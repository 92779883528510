const EngageReducer = (
  state = {
    engageVideoList: [],
    engageVideoCount: 0,
    videoCommentList: [],
    videoCommentCount: 0,
    topicList: [],
    topicCount: 0,
    topicCommentList: [],
    topicCommentCount: 0,
    category: {},
    topicUsercount: 0,
    topicUserList: [],
    orgTopicUsers: [],
    orgtopicCount: 0,
  },
  action = {}
) => {
  Object.freeze(state);
  switch (action.type) {
    case "ENGAGE/ENGAGEVIDEO_LIST":
      return {
        ...state,
        engageVideoList: action.payload.engageVideoList,
        engageVideoCount: action.payload.engageVideoCount,
      };
    // break;
    case "ENGAGE/ENGAGEVIDEO_DETAILS":
      return {
        ...state,
        engageVideoDetail: action.payload.engageVideoDetail,
      };
    // break;
    case "ENGAGE/ENGAGEVIDEOCOMMENTS_LIST":
      return {
        ...state,
        videoCommentList: action.payload.videoCommentList,
        videoCommentCount: action.payload.videoCommentCount,
      };
    // break;
    case "DISCUSSION/TOPIC_LIST":
      return {
        ...state,
        topicList: action.payload.topicList,
        topicCount: action.payload.topicCount,
      };
    // break;
    case "DISCUSSION/TOPIC_DETAILS":
      return {
        ...state,
        topicDetail: action.payload.topicDetail,
      };
    // break;
    case "DISCUSSION/TOPIC_COMMENTS_LIST":
      return {
        ...state,
        topicCommentList: action.payload.topicCommentList,
        topicCommentCount: action.payload.topicCommentCount,
      };
    case "ENGAGE/CATEGORYLIST":
      return {
        ...state,
        category: {
          categoryList: action.payload.categoryList,
          categoryCount: action.payload.categoryCount,
        },
      };
    case "DISCUSSION/RECIPIENT_LIST":
      return {
        ...state,
        topicUserList: action.payload.topicUserList,
        topicUsercount: action.payload.topicUsercount,
      };
    case "DISCUSSION/GET_USER_TOPICS":
      return {
        ...state,
        usertopicList: action.payload.usertopicList,
        usertopicCount: action.payload.topicCount,
      };

    case "DISCUSSION/GET_ORG_TOPICS":  
      return {
        ...state,
        orgTopicUsers: action.payload.orgTopicUsers,
        orgtopicCount: action.payload.orgtopicCount,
      };

    default:
      return state;
  }
};

export default EngageReducer;
