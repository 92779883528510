const INITIAL_STATE = {
  launchpadDepartmentList: [],
  launchpad_userlist: [],
  launchpad_user_count: "",
  departmentList: [],
  launchpadList: [],
  departmentCount: 0,
  launchpadCount: 0,
  launchpadFolderList: [],
  luanchpadFolderListCount: 0,
  userDepartmentList: [],
  userLaunchpadList: [],
  allLaunchpadList: [],
  launchpads_List: [],
};

const ResourceCenterReducer = (state = INITIAL_STATE, action) => {
  Object.freeze(state);
  switch (action.type) {
    case "LAUNCHPAD/DEPARTMET_BY_ORGID":
      return {
        ...state,
        launchpadDepartmentList: action.payload.launchpadDepartmentList,
      };
    case "LAUNCHPAD/USER_LIST":
      return {
        ...state,
        launchpad_userlist: action.payload.launchpad_userlist,
        launchpad_user_count: action.payload.launchpad_user_count,
      };
    case "LAUNCHPAD/DEPARTMENT_LIST":
      return {
        ...state,
        departmentList: action.payload.departmentList,
        departmentCount: action.payload.departmentCount,
      };

    case "LAUNCHPAD/LUNCHPAD_NEW_FOLDER_LIST":
      return {
        ...state,
        launchpadFolderList: action.payload.luanchpadFolderList,
        luanchpadFolderListCount: action.payload.luanchpadFolderListCount,
      };
    case "LAUNCHPAD/FOLDER_RESOURCES_LIST":
      return {
        ...state,
        resourcesList: action.payload.resourcesList,
        resourcesCount: action.payload.resourcesCount,
        launchpad_folder_name:action.payload.launchpad_folder_name,
        TotalFolderResources:action.payload.TotalFolderResources
        
      };
    case "LAUNCHPAD/USER_DEPARTMENT_LIST":
      return {
        ...state,
        userDepartmentList: action.payload.userDepartmentList,
        userDepartmentCount: action.payload.userDepartmentCount,
      };
    case "LAUNCHPAD/LAUNCHPAD_LIST":
      return {
        ...state,
        launchpadList: action.payload.launchpadList,
        launchpadCount: action.payload.launchpadCount,
      };
    case "LAUNCHPAD/ALL_LAUNCHPAD_LIST":
      return {
        ...state,
        allLaunchpadList: action.payload.launchpadList,
      };
    case "LAUNCHPAD/USERS_LAUNCHPAD_LIST":
      return {
        ...state,
        userLaunchpadList: action.payload.userLaunchpadList,
        userLaunchpadCount: action.payload.userLaunchpadCount,
      };
    case "LAUNCHPAD/ALL-LAUNCHPADS":
      return {
        ...state,
        launchpads_List: action.payload.launchpadList,
        launchpads_Count: action.payload.launchpadCount,
      };

    default:
      return state;
  }
};

export default ResourceCenterReducer;
